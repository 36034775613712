import 'intersection-observer';
import Swiper from 'swiper/bundle';
import { uaCheck } from './modules/utils';
import 'swiper/swiper-bundle.css';

uaCheck();

// ----------------
// アニメーション付与
// ----------------
const option = {
  root: null,
  rootMargin: '-10%',
  threshold: 0,
};
const callBack = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('is-show');
    }
  });
};
const observer = new IntersectionObserver(callBack, option);
const targets = document.querySelectorAll('.ani-fadeIn');
targets.forEach((target) => {
  observer.observe(target);
});

// ----------------
// Swiper
// ----------------

const swiper = new Swiper('.mySwiper', {
  loop: true,
  speed: 1000,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
  autoplay: {
    delay: 3000,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});
